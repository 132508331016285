/*
 * Page dédiée a l'édition d'une agence en particulier
 */
import {
    Box,
    Text,
    useDisclosure,
    Center,
    Spinner,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Heading,
    Wrap,
    Spacer,
    Badge,
} from '@chakra-ui/react'
import { Agence, Entreprise, Equipement, Utilisateur } from '../../model'
import { GetTargetAgence } from '../../firebaseManager/Communicator/AgenceCommunicator'
import { useSearchParams } from 'react-router-dom'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Container } from '@chakra-ui/react'
import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../auth/AuthContextProvider'
import { GetTargetEntreprise } from '../../firebaseManager/Communicator/EntrepriseCommunicator'
import { BreadCrumbBuilder } from '../../components/BreadCrumbBuilder'
import { CustomBox } from '../../components/CustomBox'
import { ConditionalConnectAdminComplex } from '../../auth/ConditionalConnectAdminComplex'
import {
    AddIcon,
    CloseIcon,
    EditIcon,
    EmailIcon,
    PhoneIcon,
    ViewIcon,
} from '@chakra-ui/icons'
import { CreationEditionAgence } from '../../components/Formulaire/CreationEditionAgence'
import { CreationEditionUtilisateur } from '../../components/Formulaire/CreationEditionUtilisateur'
import { GetAllUtilisateursTargetEntrepriseAgence } from '../../firebaseManager/Communicator/UtilisateurCommunicator'
import { ShowRole } from '../../utils/helpers'
import { ButtonToolTip } from '../../components/ButtonToolTip'
import { Button } from '../../components/Button'
import { GetAllSharedEquipementsForAgence } from '../../firebaseManager/Communicator/EquipementCommunicator'
import { CreationEditionEquipement } from '../../components/Formulaire/CreationEditionEquipement'
import { ModalSuppressionUtilisateur } from '../../components/ModalSuppression'
import moment from 'moment'

export const ManageEditAgence = () => {
    const authentification = useContext(AuthContext)

    const [targetEntreprise, setTargetEntreprise] =
        useState<Entreprise | null>()
    const [targetAgence, setTargetAgence] = useState<Agence | null>()
    const [
        allUtilisateursTargetEntrepriseAgence,
        setAllUtilisateursTargetEntrepriseAgence,
    ] = useState<Utilisateur[] | null>()

    const [allSharedEquipementsForAgence, setAllSharedEquipementsForAgence] =
        useState<Equipement[] | null>()

    const [searchParams] = useSearchParams()
    const id_entreprise: string = searchParams.get('id_entreprise')!
    const id_agence: string = searchParams.get('id_agence')!

    const {
        isOpen: isOpenEditAgence,
        onOpen: onOpenEditAgence,
        onClose: onCloseEditAgence,
    } = useDisclosure()

    const {
        isOpen: isOpenModalAddUtilisateur,
        onOpen: onOpenModalAddUtilisateur,
        onClose: onCloseModalAddUtilisateur,
    } = useDisclosure()

    const {
        isOpen: isOpenModalAddEquipement,
        onOpen: onOpenModalAddEquipement,
        onClose: onCloseModalEquipement,
    } = useDisclosure()

    function refreshTagetEntreprise() {
        GetTargetEntreprise(id_entreprise!)
            .then((result) => {
                setTargetEntreprise(result)
            })
            .catch((e) => setTargetEntreprise(null))
    }
    function refreshTagetAgence() {
        GetTargetAgence(id_agence!)
            .then((result) => {
                setTargetAgence(result)
            })
            .catch((e) => setTargetAgence(null))
    }

    function RefreshAllUtilisateursTargetEntrepriseAgence() {
        GetAllUtilisateursTargetEntrepriseAgence(id_entreprise, id_agence)
            .then((result) => {
                setAllUtilisateursTargetEntrepriseAgence(result)
            })
            .catch((e) => setAllUtilisateursTargetEntrepriseAgence(null))
    }

    function RefreshAllSharedEquipementForAgence() {
        GetAllSharedEquipementsForAgence(id_agence)
            .then((result) => {
                setAllSharedEquipementsForAgence(result)
            })
            .catch((e) => setAllSharedEquipementsForAgence([]))
    }
    useEffect(() => {
        if (authentification.firebaseUser != null) {
            if (id_entreprise != null && id_agence != null) {
                refreshTagetEntreprise()
                refreshTagetAgence()
                RefreshAllUtilisateursTargetEntrepriseAgence()
                RefreshAllSharedEquipementForAgence()
            }
        }
    }, [authentification])

    return (
        <ConditionalConnectAdminComplex>
            <BreadCrumbBuilder
                items={[
                    {
                        name: 'Entreprises',
                        url: '/manage_enterprises',
                    },
                    {
                        name: targetEntreprise?.nom!,
                        url: '/edit_enterprise?id_entreprise=' + id_entreprise,
                    },
                    {
                        name: 'Agences',
                        url: '#',
                        nolink: true,
                    },
                    {
                        name: targetAgence?.nom!,
                        url: '',
                    },
                ]}
            />
            <Container maxW="container.lg">
                {targetAgence ? (
                    <>
                        <Center>
                            <Heading>{targetAgence?.nom}</Heading>
                        </Center>
                        {!isOpenEditAgence && (
                            <CustomBox mt={'30px'}>
                                <EditIcon
                                    boxSize={6}
                                    float={'right'}
                                    onClick={onOpenEditAgence}
                                />
                                <Text ml={'10px'} as="b">
                                    Information de l'agence{' '}
                                </Text>
                                <Box m={'30px'}>
                                    <Text>
                                        <b>Nom</b>: {targetAgence?.nom}
                                    </Text>
                                    <Text>
                                        <b>Email</b> :{' '}
                                        {targetAgence?.mailContact}
                                    </Text>
                                    <Text>
                                        <b>Téléphone</b> :{' '}
                                        {targetAgence?.telContact}
                                    </Text>
                                    <Text>
                                        <b>Adresse</b> : {targetAgence?.adresse}
                                    </Text>
                                    <Text>
                                        <b>Interlocuteur</b> :{' '}
                                        {targetAgence?.interlocuteur}
                                    </Text>
                                    <Text mt="10px">
                                        <b>
                                            Objectif d'usage des utilisateurs de
                                            vélo
                                        </b>{' '}
                                        : {targetAgence?.objectif} utilisations
                                        sur l'année
                                    </Text>
                                    <Text>
                                        <b>
                                            Mois de début comptage des objectifs
                                        </b>{' '}
                                        :{' '}
                                        {targetAgence?.moisDebutObjectif !=
                                            undefined && (
                                            <>
                                                {moment()
                                                    .set(
                                                        'month',
                                                        targetAgence?.moisDebutObjectif
                                                    )
                                                    .format('MMMM')}
                                            </>
                                        )}
                                    </Text>
                                    <Text mt={'20px'}>
                                        <b>Nombre d'utilisateurs</b> :{' '}
                                        {allUtilisateursTargetEntrepriseAgence
                                            ? allUtilisateursTargetEntrepriseAgence?.length
                                            : 0}
                                    </Text>
                                    <Text>
                                        <b>Nombre d'équipements partagés</b> :{' '}
                                        {allSharedEquipementsForAgence
                                            ? allSharedEquipementsForAgence?.length
                                            : 0}
                                    </Text>
                                    <Wrap mt={'20px'}>
                                        <Spacer />

                                        <Box>
                                            <Button
                                                size={'sm'}
                                                p="10px"
                                                colorScheme="gray-dark"
                                                as={ReactRouterLink}
                                                to={
                                                    '/mes_equipements_entreprise?id_agence=' +
                                                    id_agence
                                                }
                                            >
                                                Vision parc agence
                                            </Button>
                                        </Box>
                                        <Spacer />
                                    </Wrap>
                                </Box>
                            </CustomBox>
                        )}

                        {isOpenEditAgence && (
                            <CustomBox mt={'30px'}>
                                <CloseIcon
                                    color="red"
                                    boxSize={6}
                                    float={'right'}
                                    onClick={onCloseEditAgence}
                                />
                                <Text ml={'10px'} as="b">
                                    Édition de l'entreprise
                                </Text>
                                <Box m={'30px'}>
                                    <CreationEditionAgence
                                        agence={targetAgence!}
                                        agenceId={id_agence}
                                        entrepriseId={id_entreprise}
                                        onSucess={() => {
                                            onCloseEditAgence()
                                            refreshTagetAgence()
                                        }}
                                    />
                                </Box>
                            </CustomBox>
                        )}
                    </>
                ) : (
                    <CustomBox p={'40px'} ml={'50px'} mr={'50px'} mt={'30px'}>
                        <Center>
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                            />
                        </Center>
                        <Center> Chargement de l'agence</Center>
                    </CustomBox>
                )}
                <Tabs mt={'30px'}>
                    <TabList>
                        <Tab>Utilisateurs</Tab>
                        <Tab>Équipements Partagés</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <Button
                                onClick={onOpenModalAddUtilisateur}
                                p={4}
                                mb={'30px'}
                                mt={'15px'}
                                ml={'50px'}
                            >
                                <AddIcon /> Créer un utilisateur
                            </Button>
                            {allUtilisateursTargetEntrepriseAgence == null && (
                                <CustomBox p={'40px'} ml={'50px'} mr={'50px'}>
                                    <Center>
                                        <Spinner
                                            thickness="4px"
                                            speed="0.65s"
                                            emptyColor="gray.200"
                                            color="blue.500"
                                            size="xl"
                                        />
                                    </Center>
                                    <Center>Chargement des utilisateurs</Center>
                                </CustomBox>
                            )}
                            {allUtilisateursTargetEntrepriseAgence &&
                                allUtilisateursTargetEntrepriseAgence?.length ===
                                    0 && (
                                    <CustomBox
                                        p={'40px'}
                                        ml={'50px'}
                                        mr={'50px'}
                                    >
                                        <Center>
                                            Aucun utilisateur à afficher
                                        </Center>
                                    </CustomBox>
                                )}
                            {allUtilisateursTargetEntrepriseAgence &&
                                allUtilisateursTargetEntrepriseAgence?.length >
                                    0 && (
                                    <>
                                        {allUtilisateursTargetEntrepriseAgence?.map(
                                            function (utilisateur, idx) {
                                                return (
                                                    <CustomBox
                                                        pl={'25px'}
                                                        pr={'25px'}
                                                        pt={'10px'}
                                                        pb={'10px'}
                                                        h={'68px'}
                                                        ml={'50px'}
                                                        mr={'50px'}
                                                        mb={'10px'}
                                                        key={idx}
                                                    >
                                                        <Flex>
                                                            <Box
                                                                mt={'0px'}
                                                                width={
                                                                    'calc( 100% - 190px )'
                                                                }
                                                            >
                                                                <Text
                                                                    as="b"
                                                                    fontSize="lg"
                                                                >
                                                                    {
                                                                        utilisateur.nom
                                                                    }{' '}
                                                                    {
                                                                        utilisateur.prenom
                                                                    }
                                                                </Text>
                                                                <br></br>
                                                                <Text fontSize="sm">
                                                                    {ShowRole(
                                                                        utilisateur.role
                                                                    )}
                                                                </Text>
                                                            </Box>

                                                            <Box w={'190px'}>
                                                                <ButtonToolTip
                                                                    label={
                                                                        utilisateur.mailContact!
                                                                    }
                                                                    textToCopy={
                                                                        utilisateur.mailContact!
                                                                    }
                                                                >
                                                                    <EmailIcon />
                                                                </ButtonToolTip>

                                                                <ButtonToolTip
                                                                    label={
                                                                        utilisateur.telContact!
                                                                    }
                                                                    textToCopy={
                                                                        utilisateur.telContact!
                                                                    }
                                                                >
                                                                    <PhoneIcon />
                                                                </ButtonToolTip>

                                                                <Button
                                                                    colorScheme="blue"
                                                                    p={0}
                                                                    as={
                                                                        ReactRouterLink
                                                                    }
                                                                    to={
                                                                        '/edit_utilisateur?id_utilisateur=' +
                                                                        utilisateur.id
                                                                    }
                                                                >
                                                                    <ViewIcon />
                                                                </Button>

                                                                <ModalSuppressionUtilisateur
                                                                    targetId={
                                                                        utilisateur.id
                                                                    }
                                                                    nomEntite={
                                                                        utilisateur.nom +
                                                                        ' ' +
                                                                        utilisateur.prenom
                                                                    }
                                                                    onSuccess={() =>
                                                                        RefreshAllUtilisateursTargetEntrepriseAgence()
                                                                    }
                                                                />
                                                            </Box>
                                                        </Flex>
                                                    </CustomBox>
                                                )
                                            }
                                        )}
                                    </>
                                )}

                            <Modal
                                isOpen={isOpenModalAddUtilisateur}
                                onClose={onCloseModalAddUtilisateur}
                                size={'xl'}
                            >
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>
                                        Créer un utilisateur
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <CreationEditionUtilisateur
                                            entrepriseEditable={false}
                                            entrepriseId={id_entreprise}
                                            agenceEditable={false}
                                            agenceId={id_agence}
                                            roleEditable={true}
                                            onSucess={() => {
                                                RefreshAllUtilisateursTargetEntrepriseAgence()
                                                onCloseModalAddUtilisateur()
                                            }}
                                        />
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                        </TabPanel>
                        <TabPanel>
                            <Button
                                onClick={onOpenModalAddEquipement}
                                p={4}
                                mb={'30px'}
                                mt={'15px'}
                                ml={'50px'}
                            >
                                <AddIcon /> Créer un équipement
                            </Button>
                            {allSharedEquipementsForAgence == null && (
                                <CustomBox p={'40px'} ml={'50px'} mr={'50px'}>
                                    <Center>
                                        <Spinner
                                            thickness="4px"
                                            speed="0.65s"
                                            emptyColor="gray.200"
                                            color="blue.500"
                                            size="xl"
                                        />
                                    </Center>
                                    <Center>Chargement des équipements</Center>
                                </CustomBox>
                            )}
                            {allSharedEquipementsForAgence &&
                                allSharedEquipementsForAgence?.length === 0 && (
                                    <CustomBox
                                        p={'40px'}
                                        ml={'50px'}
                                        mr={'50px'}
                                    >
                                        <Center>
                                            Aucun équipement à afficher
                                        </Center>
                                    </CustomBox>
                                )}
                            {allSharedEquipementsForAgence &&
                                allSharedEquipementsForAgence?.length > 0 && (
                                    <Wrap>
                                        {allSharedEquipementsForAgence?.map(
                                            function (equipement, idx) {
                                                return (
                                                    <CustomBox
                                                        pl={'25px'}
                                                        pr={'25px'}
                                                        pt={'10px'}
                                                        pb={'10px'}
                                                        ml={'5px'}
                                                        mr={'5px'}
                                                        mb={'15px'}
                                                        w={'calc(33% - 15px)'}
                                                        key={idx}
                                                    >
                                                        <Box
                                                            float={'right'}
                                                            as={ReactRouterLink}
                                                            to={
                                                                '/edit_equipement?id_equipement=' +
                                                                equipement.id
                                                            }
                                                        >
                                                            <ViewIcon
                                                                boxSize={6}
                                                            />
                                                        </Box>

                                                        <Text
                                                            as="b"
                                                            fontSize="lg"
                                                        >
                                                            {
                                                                equipement.categorie
                                                            }
                                                            {equipement.hivernage && (
                                                                <Badge
                                                                    colorScheme="red"
                                                                    ml={'5px'}
                                                                >
                                                                    HIV
                                                                </Badge>
                                                            )}
                                                            {!equipement.utilisateurAppartenance && (
                                                                <Badge
                                                                    colorScheme="green"
                                                                    ml={'5px'}
                                                                >
                                                                    PART
                                                                </Badge>
                                                            )}
                                                        </Text>

                                                        <Text fontSize="sm">
                                                            <b>Marque :</b>{' '}
                                                            {equipement.marque}
                                                        </Text>
                                                        <Text fontSize="sm">
                                                            <b>Modèle :</b>{' '}
                                                            {equipement.modele}
                                                        </Text>
                                                        <Text fontSize="sm">
                                                            <b>N° Série :</b>{' '}
                                                            {
                                                                equipement.numeroSerie
                                                            }
                                                        </Text>
                                                        <Text fontSize="sm">
                                                            <b>N° Marquage :</b>{' '}
                                                            {
                                                                equipement.numeroMarquage
                                                            }
                                                        </Text>
                                                    </CustomBox>
                                                )
                                            }
                                        )}
                                    </Wrap>
                                )}
                            <Modal
                                isOpen={isOpenModalAddEquipement}
                                onClose={onCloseModalEquipement}
                                size={'xl'}
                            >
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Créer une agence</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <CreationEditionEquipement
                                            utilisateurEditable={false}
                                            entrepriseId={id_entreprise}
                                            entrepriseEditable={false}
                                            agenceId={id_agence}
                                            agenceEditable={false}
                                            onSucess={() => {
                                                RefreshAllSharedEquipementForAgence()
                                                onCloseModalEquipement()
                                            }}
                                        />
                                    </ModalBody>
                                </ModalContent>
                            </Modal>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>
        </ConditionalConnectAdminComplex>
    )
}
