/*
 * bouton ouvrant une modal et permettant de modifier l'email d'un utilisateur.
 * La modification de mail va entrainer la modification de valeur dans l'objet utilisateur firebase, mais aussi la modification sur l'entité dans firebase AUTH
 */

import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    InputGroup,
    InputLeftAddon,
    Box,
    ModalOverlay,
    useDisclosure,
    useToast,
    Input,
    Center,
    List,
    Select,
} from '@chakra-ui/react'

import { Button } from '../Button'
import { useState } from 'react'

import { CallFunctionExportCalendrierAggregateParMoisDepuisDate } from '../../firebaseManager/Communicator/FunctionCallManager'
import moment from 'moment'
import { downloadTxtFile } from '../../utils/helpers'

export type Props = {
    agenceId: string
    entrepriseId?: string
    onSuccess?: () => void
}

export const ModalExportUsageAgenceEntreDate = (props: Props) => {
    const toast = useToast()
    const {
        isOpen: isOpenModal,
        onOpen: onOpenModal,
        onClose: onCloseModal,
    } = useDisclosure()
    const [isLoading, setisLoading] = useState<boolean>(false)
    const [targetYear, setTargetYear] = useState<number>(2024)
    const [targetMonth, setTargetMonth] = useState<number>(6)

    function exportData() {
        // Faire un check sur annee et mois si valide.
        // si non valide --> toast erreur
        // si valide loading et go
        const year = targetYear
        const month = targetMonth
        const target = moment().set('year', year).set('month', month)
        const now = moment()

        if (now < target) {
            toast({
                title: 'Date incorrecte',
                status: 'error',
                duration: 2000,
                isClosable: true,
            })
        } else {
            setisLoading(true)
            CallFunctionExportCalendrierAggregateParMoisDepuisDate(
                year,
                month,
                props.agenceId,
                props.entrepriseId
            )
                .then((data) => {
                    downloadTxtFile(
                        'Usage_' +
                            (month + 1) +
                            '-' +
                            year +
                            '_to_' +
                            (now.month() + 1) +
                            '-' +
                            now.year() +
                            '.csv',
                        data.data as string,
                        true
                    )
                    setisLoading(false)
                })
                .catch((e: any) => {
                    console.log(e)
                    toast({
                        title: "Erreur dans la récupération des données d'export",
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                    })
                    setisLoading(false)
                })
        }
    }
    return (
        <>
            <Button
                colorScheme="blue"
                p={5}
                ml={'10px'}
                onClick={(e) => {
                    onOpenModal()
                }}
            >
                Exporter usage
            </Button>
            <Modal isOpen={isOpenModal} onClose={onCloseModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Exporter l'usage depuis une date définie
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        L'export comprendra les données entre la date
                        selectionnée et aujourd'hui.
                        <Center w={'100%'} mt="20px">
                            <InputGroup w={'50%'}>
                                <Select
                                    placeholder="Selection du mois"
                                    value={targetMonth}
                                    onChange={(value) => {
                                        setTargetMonth(
                                            Number(value.target.value)
                                        )
                                    }}
                                >
                                    <option value={0}>Janvier</option>
                                    <option value={1}>Février</option>
                                    <option value={2}>Mars</option>
                                    <option value={3}>Avril</option>
                                    <option value={4}>Mai</option>
                                    <option value={5}>Juin</option>
                                    <option value={6}>Juillet</option>
                                    <option value={7}>Août</option>
                                    <option value={8}>Septembre</option>
                                    <option value={9}>Octobre</option>
                                    <option value={10}>Novembre</option>
                                    <option value={11}>Décembre</option>
                                </Select>
                            </InputGroup>
                            <InputGroup w={'50%'}>
                                <Select
                                    placeholder="Selection de l'année"
                                    value={targetYear}
                                    onChange={(value) => {
                                        setTargetYear(
                                            Number(value.target.value)
                                        )
                                    }}
                                >
                                    <option value={2024}>2024</option>
                                    <option value={2025}>2025</option>
                                </Select>
                            </InputGroup>
                        </Center>
                    </ModalBody>

                    <ModalFooter>
                        <Center>
                            <Button
                                isLoading={isLoading}
                                mr={3}
                                colorScheme={'blue'}
                                onClick={(e) => {
                                    exportData()
                                }}
                            >
                                {isLoading ? 'Exportation...' : 'Export '}
                            </Button>
                        </Center>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
