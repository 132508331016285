/*
 * Page dédiée a l'affichages des informations de compte de utilisateur connecté
 */
import {
    Center,
    Container,
    Text,
    useToast,
    Select,
    HStack,
    Box,
} from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { UtilisateurContext } from '../../auth/UtilisateurContextProvider'
import { useContext, useState } from 'react'

import { CustomBox } from '../../components/CustomBox'
import { Button } from '../../components/Button'
import { UpdatePasswordClient } from '../../components/Formulaire/UpdatePasswordClient'
import { ShowRole } from '../../utils/helpers'
import { ConditionalConnectAdminComplex } from '../../auth/ConditionalConnectAdminComplex'
import { ExportActionButton } from '../../components/ExportActionButton'
import {
    CallFunctionAggregateCalendrierStats,
    CallFunctionAggregateCalendrierUsageAnnuelSurUtilisateurs,
} from '../../firebaseManager/Communicator/FunctionCallManager'
import moment from 'moment'
import { BlocTroisStats } from '../../components/BlocTroisStats'
import {
    updateDoc,
    doc,
    query,
    collection,
    where,
    getDocs,
} from 'firebase/firestore'
import { db } from '../../firebaseManager'
import { Equipement } from '../../model'

export const Administration = () => {
    const utilisateur = useContext(UtilisateurContext)
    const [
        LoadingUpdateAggregateCalendriers,
        setLoadingUpdateAggregateCalendriers,
    ] = useState<boolean>(false)
    const [
        LoadingUpdateAggregateCalendrierUsageAnnuelSurUtilisateurs,
        setLoadingUpdateAggregateCalendrierUsageAnnuelSurUtilisateurs,
    ] = useState<boolean>(false)
    const toast = useToast()

    const [targetMonth, setTargetMonth] = useState<number>(moment().month())
    const [targetYear, setTargetYear] = useState<number>(moment().year())
    return (
        <>
            <ConditionalConnectAdminComplex>
                <Container maxW="container.md">
                    {/**
                     <CustomBox mt={'30px'} padding={'20px'}>
                        <Text as="b">Information compte </Text>
                        <Text>
                            <b>Nom</b> : {utilisateur?.nom}
                        </Text>
                        <Text>
                            <b>Prenom</b> : {utilisateur?.prenom}
                        </Text>
                        <Text>
                            <b>Rôle</b> : {ShowRole(utilisateur?.role)}
                        </Text>
                    </CustomBox>
                    */}
                    <Box my={'60px'}>
                        <BlocTroisStats globalValue />
                    </Box>

                    <CustomBox mt={'10px'} padding={'20px'}>
                        <Text as="b">Actualisation des compteurs annuels </Text>
                        <Text>
                            Permet de réactualiser les compteurs d'usage de vélo
                            pour chaque utilisateur. Cette réactualisation va
                            vérifier les calendrier vélotaf et compter le nombre
                            d'usage total d'utilisation en prenant en compte le
                            paramètre d'agence "Mois de début comptage des
                            objectifs". Cette valeur permet par la suite de
                            connaître l'avance sur objectif de chaque
                            utilisateur.
                        </Text>
                        <HStack>
                            {/** <Select
                                w={'20%'}
                                placeholder="Selection de l'année"
                                value={targetYear}
                                onChange={(value) => {
                                    setTargetYear(Number(value.target.value))
                                }}
                            >
                                <option value={2024}>2024</option>
                                <option value={2025}>2025</option>
                                <option value={2025}>2026</option>
                                <option value={2025}>2027</option>
                            </Select> */}

                            <Button
                                disabled={
                                    LoadingUpdateAggregateCalendrierUsageAnnuelSurUtilisateurs
                                }
                                onClick={(e) => {
                                    setLoadingUpdateAggregateCalendrierUsageAnnuelSurUtilisateurs(
                                        true
                                    )
                                    CallFunctionAggregateCalendrierUsageAnnuelSurUtilisateurs(
                                        targetYear
                                    )
                                        .then((data) => {
                                            console.log(data)
                                            toast({
                                                title: 'Actualisation terminée!',
                                                description: '',
                                                status: 'success',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                        })
                                        .catch((e) => {
                                            toast({
                                                title: "Erreur dans l'actualisation des compteurs vélotaf des utilisateurs",
                                                description: '',
                                                status: 'error',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                        })
                                        .finally(() => {
                                            setLoadingUpdateAggregateCalendrierUsageAnnuelSurUtilisateurs(
                                                false
                                            )
                                        })
                                }}
                                p={4}
                                py={'0px'}
                                px={'10px'}
                            >
                                {LoadingUpdateAggregateCalendrierUsageAnnuelSurUtilisateurs
                                    ? 'Actualisation...'
                                    : 'Actualiser les compteurs annuels'}
                            </Button>
                        </HStack>
                    </CustomBox>
                    <CustomBox mt={'10px'} padding={'20px'}>
                        <Text as="b">
                            Actualisation des calendriers Agence et Entreprise{' '}
                        </Text>
                        <Text>
                            Permet de réactualiser les calendriers des agences
                            et des entreprises pour le mois et l'année cible.
                        </Text>
                        <HStack>
                            <Select
                                w={'20%'}
                                placeholder="Selection du mois"
                                value={targetMonth}
                                onChange={(value) => {
                                    setTargetMonth(Number(value.target.value))
                                }}
                            >
                                <option value={0}>Janvier</option>
                                <option value={1}>Février</option>
                                <option value={2}>Mars</option>
                                <option value={3}>Avril</option>
                                <option value={4}>Mai</option>
                                <option value={5}>Juin</option>
                                <option value={6}>Juillet</option>
                                <option value={7}>Août</option>
                                <option value={8}>Septembre</option>
                                <option value={9}>Octobre</option>
                                <option value={10}>Novembre</option>
                                <option value={11}>Décembre</option>
                            </Select>
                            <Select
                                w={'20%'}
                                placeholder="Selection de l'année"
                                value={targetYear}
                                onChange={(value) => {
                                    setTargetYear(Number(value.target.value))
                                }}
                            >
                                <option value={2024}>2024</option>
                                <option value={2025}>2025</option>
                                <option value={2025}>2026</option>
                                <option value={2025}>2027</option>
                            </Select>
                            <Button
                                disabled={LoadingUpdateAggregateCalendriers}
                                onClick={(e) => {
                                    setLoadingUpdateAggregateCalendriers(true)
                                    CallFunctionAggregateCalendrierStats(
                                        targetMonth,
                                        targetYear
                                    )
                                        .then((data) => {
                                            console.log(data)
                                            toast({
                                                title: 'Actualisation terminée!',
                                                description: '',
                                                status: 'success',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                        })
                                        .catch((e) => {
                                            toast({
                                                title: "Erreur dans l'actualisation des statistiques calendriers vélotaf",
                                                description: '',
                                                status: 'error',
                                                duration: 2000,
                                                isClosable: true,
                                            })
                                        })
                                        .finally(() => {
                                            setLoadingUpdateAggregateCalendriers(
                                                false
                                            )
                                        })
                                }}
                                p={4}
                                py={'0px'}
                                px={'10px'}
                            >
                                {LoadingUpdateAggregateCalendriers
                                    ? 'Actualisation...'
                                    : 'Actualiser les calendriers consolidés'}
                            </Button>
                        </HStack>
                    </CustomBox>
                    <CustomBox mt={'10px'} padding={'20px'}>
                        <Text as="b">Centre des exports</Text>
                        <Text mt={'10px'}>
                            <b>Exporter l'ensemble des équipements</b> :{' '}
                            <ExportActionButton
                                equipements
                                customButton
                                customButtonText="Export Équipements"
                            />
                        </Text>
                        <Text mt={'10px'}>
                            <b>Exporter l'ensemble des vélos</b>, contient les
                            informations de vélo ainsi que l'historique des
                            actions associées :{' '}
                            <ExportActionButton
                                exportAllVelo
                                customButton
                                customButtonText="Export Vélo avec historique"
                            />
                        </Text>
                        <Text mt={'10px'}>
                            <b>Exporter l'ensemble des utilisateurs</b> :{' '}
                            <ExportActionButton
                                users
                                customButton
                                customButtonText="Export Utilisateurs"
                            />
                        </Text>
                    </CustomBox>
                    <CustomBox mt={'80px'} padding={'20px'}>
                        <Text as="b">Modification mon mot de passe</Text>
                        <UpdatePasswordClient />
                    </CustomBox>
                    <Center mt={'30px'}>
                        <Button
                            as={ReactRouterLink}
                            to={'/logout'}
                            backgroundColor={'grey.900'}
                        >
                            Deconnexion
                        </Button>
                    </Center>
                </Container>
            </ConditionalConnectAdminComplex>
        </>
    )
}

/** 
 * 
 * exemple de script pour update en masse des numéro d'assurance depuis numéro de marquage
    Usage :         await updateNumeroAssruance('RK663308WZ', 'EUX_N_HE')

    async function updateNumeroAssruance(marquage: string, numeroAss: string) {
        const q = query(
            collection(db, 'equipements'),
            where('numeroMarquage', '==', marquage)
        )
        const querySnapshot = await getDocs(q)
        if (querySnapshot.empty) {
            console.log('KO : ' + marquage + '     ' + numeroAss)
            return
        }
        const idEquipement = querySnapshot.docs[0].id

        updateDoc(doc(db, 'equipements/' + idEquipement), {
            numeroDeContratAssurance: numeroAss,
        })
        console.log(
            'OK : id = ' +
                idEquipement +
                '      ' +
                marquage +
                '     ' +
                numeroAss
        )
    }

*/
