/**
 * Composant affichant les informations d'un accessoire
 */

import { useState } from 'react'
import { Button as ButtonCustom } from '../Button'

import {
    CallFunctionAdminExportAllEquipementData,
    CallFunctionAdminExportAllUsersData,
    CallFunctionAdminExportVeloEntrepriseData,
    CallFunctionAdminExportVeloAllEntreprisesData,
} from '../../firebaseManager/Communicator/FunctionCallManager'
import { CalendrierUsageAggregate, Entreprise } from '../../model'
import { Button, Center } from '@chakra-ui/react'
import { downloadTxtFile } from '../../utils/helpers'

interface Props {
    users?: boolean
    equipements?: boolean
    exportVelo?: boolean
    entreprise?: Entreprise
    exportAllVelo?: boolean
    calendrierUsageAggregate?: CalendrierUsageAggregate
    customButton?: boolean
    customButtonText?: string
}

export const ExportActionButton = (props: Props) => {
    const [loadingExport, setLoadingExport] = useState<boolean>()

    function exportdata() {
        if (props.exportVelo && props.entreprise) {
            setLoadingExport(true)
            CallFunctionAdminExportVeloEntrepriseData(props.entreprise!.id)
                .then((data) => {
                    downloadTxtFile(
                        'Velo_' + props.entreprise!.nom + '_',
                        data.data as string,
                        true
                    )
                    setLoadingExport(false)
                })
                .catch((e: any) => {
                    console.log(e)
                    setLoadingExport(false)
                })
        }
        if (props.exportAllVelo) {
            setLoadingExport(true)
            CallFunctionAdminExportVeloAllEntreprisesData()
                .then((data) => {
                    downloadTxtFile(
                        'Velo_Entreprises_',
                        data.data as string,
                        true
                    )
                    setLoadingExport(false)
                })
                .catch((e: any) => {
                    console.log(e)
                    setLoadingExport(false)
                })
        }
        if (props.equipements) {
            setLoadingExport(true)
            CallFunctionAdminExportAllEquipementData()
                .then((data) => {
                    downloadTxtFile('Equipement', data.data as string, true)
                    setLoadingExport(false)
                })
                .catch((e: any) => {
                    console.log(e)
                    setLoadingExport(false)
                })
        }
        if (props.users) {
            setLoadingExport(true)
            CallFunctionAdminExportAllUsersData()
                .then((data) => {
                    downloadTxtFile('Utilisateurs', data.data as string, true)
                    setLoadingExport(false)
                })
                .catch((e: any) => {
                    console.log(e)
                    setLoadingExport(false)
                })
        }
        if (props.calendrierUsageAggregate) {
            setLoadingExport(true)

            const cal: CalendrierUsageAggregate = props.calendrierUsageAggregate
            let stringCsv = 'date;nombre utilisation;'
            if (cal.details) {
                cal.details.forEach((detail) => {
                    stringCsv += detail.nom + ';'
                })
            }
            stringCsv += '\n'
            for (let i = 0; i < cal.usage.length; i++) {
                const date: string =
                    i + 1 + '/' + (cal.month + 1) + '/' + cal.year
                stringCsv += date + ';' + cal.usage[i] + ';'
                if (cal.details) {
                    cal.details.forEach((detail) => {
                        stringCsv += detail.usage[i] + ';'
                    })
                }
                stringCsv += '\n'
            }
            if (cal.agenceAppartenance) {
                downloadTxtFile(
                    'Agence_' + (cal.month + 1) + '_' + cal.year,
                    stringCsv,
                    false
                )
            } else if (cal.entrepriseAppartenance) {
                downloadTxtFile(
                    'Entreprise_' + (cal.month + 1) + '_' + cal.year,
                    stringCsv,
                    false
                )
            }
            setLoadingExport(false)
        }
    }

    return (
        <>
            {props.customButton && (
                <ButtonCustom
                    isDisabled={loadingExport}
                    onClick={exportdata}
                    py={'0px'}
                    px={'10px'}
                >
                    {loadingExport ? 'Exportation...' : props.customButtonText}
                </ButtonCustom>
            )}
            {!props.customButton && (
                <>
                    {props.users && (
                        <ButtonCustom
                            isDisabled={loadingExport}
                            onClick={exportdata}
                            position={'absolute'}
                            right={'20px'}
                            top={'70px'}
                            py={'0px'}
                            px={'10px'}
                        >
                            {loadingExport
                                ? 'Exportation...'
                                : 'Export utilisateurs'}
                        </ButtonCustom>
                    )}
                    {props.equipements && (
                        <ButtonCustom
                            isDisabled={loadingExport}
                            onClick={exportdata}
                            position={'absolute'}
                            right={'20px'}
                            top={'70px'}
                            py={'0px'}
                            px={'10px'}
                        >
                            {loadingExport
                                ? 'Exportation...'
                                : 'Export équipements'}
                        </ButtonCustom>
                    )}
                    {props.exportAllVelo && (
                        <ButtonCustom
                            isDisabled={loadingExport}
                            onClick={exportdata}
                            position={'absolute'}
                            right={'20px'}
                            top={'70px'}
                            py={'0px'}
                            px={'10px'}
                        >
                            {loadingExport ? 'Exportation...' : 'Export vélos'}
                        </ButtonCustom>
                    )}
                    {props.exportVelo && (
                        <ButtonCustom
                            isDisabled={loadingExport}
                            onClick={exportdata}
                            py={'0px'}
                            px={'10px'}
                        >
                            {loadingExport ? 'Exportation...' : 'Export vélos'}
                        </ButtonCustom>
                    )}
                    {props.calendrierUsageAggregate && (
                        <Center mb={'20px'}>
                            <Button
                                isDisabled={loadingExport}
                                onClick={exportdata}
                            >
                                {loadingExport
                                    ? 'Exportation...'
                                    : 'Export du mois'}
                            </Button>
                        </Center>
                    )}
                </>
            )}
        </>
    )
}
