/*
 * Formulaire autonome et réutilisable permettant de créer ou d'éditer un objet Agence
 */

import { db } from '../../../firebaseManager'
import {
    FormControl,
    Input,
    InputGroup,
    InputLeftAddon,
    useToast,
    Center,
    Text,
    Select,
} from '@chakra-ui/react'
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore'
import { Button } from '../../Button'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Agence } from '../../../model'

const validationSchema = yup.object().shape({
    nom: yup.string().required(),
    mailContact: yup.string(),
    telContact: yup.string(),
    adresse: yup.string(),
    interlocuteur: yup.string(),
    entrepriseAppartenance: yup.string().required(),
    objectif: yup.number().required(),
    moisDebutObjectif: yup.number().required(),
})

export type AddAgenceValue = {
    nom: string
    mailContact: string
    telContact: string
    adresse: string
    interlocuteur: string
    entrepriseAppartenance: string
    objectif: number
    moisDebutObjectif: number
}

export type Props = {
    agence?: Agence
    agenceId?: string
    entrepriseId: string
    onSucess?: () => void
}

export const CreationEditionAgence = (props: Props) => {
    const editMode =
        props.agence != null &&
        props.agence !== undefined &&
        props.agenceId != null
    const toast = useToast()
    const formikAddAgence = useFormik({
        validationSchema,
        initialValues: {
            nom: editMode && props.agence!.nom ? props.agence!.nom : '',
            mailContact:
                editMode && props.agence!.mailContact
                    ? props.agence!.mailContact
                    : '',
            telContact:
                editMode && props.agence!.telContact
                    ? props.agence!.telContact
                    : '',
            adresse:
                editMode && props.agence!.adresse ? props.agence!.adresse : '',
            interlocuteur:
                editMode && props.agence!.interlocuteur
                    ? props.agence!.interlocuteur
                    : '',
            entrepriseAppartenance: props.entrepriseId,
            objectif:
                editMode && props.agence!.objectif
                    ? props.agence!.objectif
                    : 50,
            moisDebutObjectif:
                editMode && props.agence!.moisDebutObjectif
                    ? props.agence!.moisDebutObjectif
                    : 0,
        },
        onSubmit: (values: AddAgenceValue, actions) => {
            if (editMode) {
                updateDoc(doc(db, 'agences/' + props.agenceId), {
                    ...values,
                })
                    .then((result) => {
                        actions.setSubmitting(false)
                        actions.resetForm()
                        if (props.onSucess) props.onSucess()
                        toast({
                            title: 'Agence mise a jour!',
                            description: '',
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                        })
                    })
                    .catch((e) => {
                        console.log('error on edit ' + e)
                        actions.setSubmitting(false)
                    })
            } else {
                setDoc(doc(collection(db, 'agences')), values)
                    .then((result) => {
                        actions.setSubmitting(false)
                        actions.resetForm()
                        if (props.onSucess) props.onSucess()
                        toast({
                            title: 'Agence créé!',
                            description: '',
                            status: 'success',
                            duration: 2000,
                            isClosable: true,
                        })
                    })
                    .catch((e) => {
                        console.log('error on add' + e)
                        actions.setSubmitting(false)
                    })
            }
        },
    })

    return (
        <FormikProvider value={formikAddAgence}>
            <FormControl id="nom" mt={4}>
                <InputGroup>
                    <InputLeftAddon>
                        Nom <Text color={'red'}>*</Text>
                    </InputLeftAddon>
                    <Input
                        name="nom"
                        type="text"
                        onChange={formikAddAgence.handleChange}
                        value={formikAddAgence.values.nom}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="mailContact" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Mail de contact" />
                    <Input
                        name="mailContact"
                        type="text"
                        onChange={formikAddAgence.handleChange}
                        value={formikAddAgence.values.mailContact}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="telContact" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Téléphone de contact" />{' '}
                    <Input
                        name="telContact"
                        type="text"
                        onChange={formikAddAgence.handleChange}
                        value={formikAddAgence.values.telContact}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="adresse" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Adresse" />{' '}
                    <Input
                        name="adresse"
                        type="text"
                        onChange={formikAddAgence.handleChange}
                        value={formikAddAgence.values.adresse}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="interlocuteur" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Interlocuteur" />{' '}
                    <Input
                        name="interlocuteur"
                        type="text"
                        onChange={formikAddAgence.handleChange}
                        value={formikAddAgence.values.interlocuteur}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="objectif" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Objectif d'utilisation par ans" />{' '}
                    <Input
                        name="objectif"
                        type="number"
                        onChange={formikAddAgence.handleChange}
                        value={formikAddAgence.values.objectif}
                    />
                </InputGroup>
            </FormControl>
            <FormControl id="moisDebutObjectif" mt={4}>
                <InputGroup>
                    <InputLeftAddon children="Mois de départ du comptage d'objectif" />{' '}
                    <Select
                        w={'20%'}
                        placeholder="Selection du mois"
                        value={formikAddAgence.values.moisDebutObjectif}
                        onChange={formikAddAgence.handleChange}
                    >
                        <option value={0}>Janvier</option>
                        <option value={1}>Février</option>
                        <option value={2}>Mars</option>
                        <option value={3}>Avril</option>
                        <option value={4}>Mai</option>
                        <option value={5}>Juin</option>
                        <option value={6}>Juillet</option>
                        <option value={7}>Août</option>
                        <option value={8}>Septembre</option>
                        <option value={9}>Octobre</option>
                        <option value={10}>Novembre</option>
                        <option value={11}>Décembre</option>
                    </Select>
                </InputGroup>
            </FormControl>
            <Center>
                <Button
                    mt={4}
                    mr={3}
                    onClick={formikAddAgence.submitForm}
                    isDisabled={
                        !formikAddAgence.isValid || !formikAddAgence.dirty
                    }
                    isLoading={formikAddAgence.isSubmitting}
                >
                    {editMode ? <>Editer</> : <>Ajouter</>}
                </Button>
            </Center>
        </FormikProvider>
    )
}
